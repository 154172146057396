<template>
    <!-- 手动下单、量化工具 -->
    <div class="toolBox" :class="{toolBox_phone:isMobile==1}">
        <ul class="tabTit">
            <li :class="{ active: tabIndex == 1 }" @click="switchToolTab(1)">
                {{ $t('stocks.order') }}</li>
            <li :class="{ active: tabIndex == 0 }" @click="switchToolTab(0)">
                {{ (web_type == 0 ? 'CEX ' : web_type == 1 ? 'DEX ' : 'NFT ') + $t('stocks.tools') }}</li>
        </ul>
        <!-- 量化工具 -->
        <div class="order_action_tool" v-if="tabIndex == 0">
            <!-- cex/dex/nft量化工具 -->
            <div class="list" v-if="!isTool&&isMobile==0">
                <template v-if="web_type == 0">
                    <!-- cex量化工具 -->
                    <div class="item" v-for="(item, index) in toolList" :key="index">
                        <div class="left" :class="{ notOpen: type < 2 }">
                            <div class="title">
                                <span class="icon">
                                    <img :src="require('@/assets/images/' + item + '.png')" />
                                </span>{{ $t('tools.' + item + '.name') }}
                            </div>
                            <div class="desc">{{ $t('tools.' + item + '.sub') }}</div>
                        </div>
                        <div class="right">
                            <div class="btn" @click="handleCreate(item,type >= 2)">
                                {{ type >= 2?$t('common.into'):$t('common.open') }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="web_type == 1">
                    <!-- dex量化工具 -->
                    <div class="item" v-for="(item, index) in toolDexList" :key="'0' + index">
                        <div class="left" :class="{ notOpen: type < 4 && item != 'manage' }">
                            <div class="title">
                                <span class="icon">
                                    <img :src="require('@/assets/images/' + item + '.png')" />
                                </span>{{ $t('tools.' + item + '.name') }}
                            </div>
                            <div class="desc">{{ $t('tools.' + item + '.sub') }}</div>
                        </div>
                        <div class="right">
                            <div class="btn" @click="handleCreate(item,type >= 4 || item == 'manage')">
                                {{ type >= 4 || item == 'manage'?$t('common.into'):$t('common.open') }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="web_type == 2">
                    <!-- nft量化工具 -->
                    <div class="item" v-for="(item, index) in toolNFTList" :key="index">
                        <div class="left" :class="{ notOpen: type < 4 }">
                            <div class="title"><span class="icon"><img
                                        :src="require('@/assets/images/' + (item == 'manageNFT' ? 'manage' : item) + '.png')" /></span>{{
                                $t('tools.' + item + '.name') }}
                            </div>
                            <div class="desc">{{ $t('tools.' + item + '.sub') }}</div>
                        </div>
                        <div class="right">
                            <div class="btn" @click="handleCreate(item,type >= 2)">
                                {{ type >= 2?$t('common.into'):$t('common.open') }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <!-- 量化工具表单 v-if="isTool||isMobile==1" -->
            <div class="form" v-else>
                <div class="hd" v-if="isMobile==0">
                    <div class="left">
                        <span class="icon">
                            <img :src="require('@/assets/images/' + selectTool + '.png')" />
                        </span>
                        {{ $t('tools.' + selectTool + '.name') }}
                    </div>
                    <div class="right">
                        <div class="help" @click="handleHelp">
                            <i class="iconfont icon-tips"></i>
                            {{$t('common.description') }}
                        </div>
                        <div class="close" @click="isTool = false"><i class="iconfont icon-x"></i></div>
                    </div>
                </div>
                <div class="hd" v-else>
                    <div class="left" @click="openToolSelect">
                        {{$t('tools.'+selectTool+'.name')}}
                        <i class="iconfont2 icon-right_arrow"></i>
                    </div>
                    <div class="right">
                        <div class="help" @click="handleHelp"><i class="iconfont icon-tips"></i>
                        </div>
                    </div>
                </div>
                <div class="action_form">
                    <!-- 批量下买单/卖单 -->
                    <div v-if="web != 'opensea' && (selectTool == 'check' || selectTool == 'sale')">
                        <div class="title">
                            {{ selectTool == 'check' ? $t('stocks.check') : $t('stocks.sale') }}
                        </div>
                        <div class="inp_box">
                            <el-input v-model="toolform.lowPrice"
                                @input.native="inputHandler($event, 'toolform', 'lowPrice')"
                                :placeholder="$t('common.minValue')" class="inp textCenter" />
                            <span class="g_to">{{ $t('common.to') }}</span>
                            <el-input v-model="toolform.highPrice"
                                @input.native="inputHandler($event, 'toolform', 'highPrice')"
                                :placeholder="$t('common.maxValue')" class="inp textCenter" />
                        </div>
                        <div class="title">{{ $t('stocks.quantity') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.lowNum"
                                @input.native="inputHandler($event, 'toolform', 'lowNum')"
                                :placeholder="$t('common.minValue')" class="inp textCenter" />
                            <span class="g_to">{{ $t('common.to') }}</span>
                            <el-input v-model="toolform.highNum"
                                @input.native="inputHandler($event, 'toolform', 'highNum')"
                                :placeholder="$t('common.maxValue')" class="inp textCenter" />
                        </div>
                        <div class="title">{{ $t('stocks.total') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.totalNum"
                                @input.native="inputHandler2($event, 'toolform', 'totalNum')"
                                :placeholder="$t('common.max') + ' 500'" class="inp" />
                        </div>
                        <div class="title">{{ $t('stocks.decimal') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.decimal"
                                @input.native="inputHandler2($event, 'toolform', 'decimal')"
                                :placeholder="$t('common.value')" class="inp" />
                        </div>
                        <div class="title">{{ $t('robot.quantityDecimal') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.quantityDecimal"
                                @input.native="inputHandler2($event, 'toolform', 'quantityDecimal')"
                                :placeholder="$t('common.value')" class="inp" />
                        </div>
                    </div>
                    <!-- 批量撤单 -->
                    <div v-else-if="selectTool == 'revoke'">
                        <div class="title">{{ $t('stocks.priceRange') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.lowPrice"
                                @input.native="inputHandler($event, 'toolform', 'lowPrice')"
                                :placeholder="$t('common.minValue')" class="inp textCenter" />
                            <span class="g_to">{{ $t('common.to') }}</span>
                            <el-input v-model="toolform.highPrice"
                                @input.native="inputHandler($event, 'toolform', 'highPrice')"
                                :placeholder="$t('common.maxValue')" class="inp textCenter" />
                        </div>
                        <div class="title">{{ $t('stocks.quantityRange') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.lowNum"
                                @input.native="inputHandler($event, 'toolform', 'lowNum')"
                                :placeholder="$t('common.minValue')" class="inp textCenter" />
                            <span class="g_to">{{ $t('common.to') }}</span>
                            <el-input v-model="toolform.highNum"
                                @input.native="inputHandler($event, 'toolform', 'highNum')"
                                :placeholder="$t('common.maxValue')" class="inp textCenter" />
                        </div>
                    </div>
                    <!-- dex -->
                    <div v-else-if="selectTool == 'calculate'">
                        <div class="typeTitle">{{ $t('stocks.estimateVolume') }}</div>
                        <div class="title">{{ $t('stocks.target') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.target"
                                @input.native="inputHandler($event, 'toolform', 'target')"
                                :placeholder="$t('common.value')" class="inp" />
                            <span class="company green">{{ yenUnit2 }}</span>
                        </div>
                        <div class="bottom">
                            <div class="g_btn btn bigbtn" @click="submitOrderCalculate(1)">
                                {{ $t('common.submit') }}</div>
                        </div>

                        <div class="typeTitle">{{ $t('stocks.estimatePrice') }}</div>
                        <div class="title">{{ $t('stocks.tradingVolume') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.targetNum"
                                @input.native="inputHandler($event, 'toolform', 'targetNum')"
                                :placeholder="$t('common.value')" class="inp" />
                            <span class="company" :class="toolform.tradeType == 1 ? 'green' : 'red'">{{
                                !toolform.tradeType ? '' : toolform.tradeType == 1 ? yenUnit2 : yenUnit1 }}</span>
                        </div>
                        <div class="title">{{ $t('stocks.transactionType') }}</div>
                        <el-radio-group class="radio_box" v-model="toolform.tradeType" @change="transactionTypeChange">
                            <el-radio label="1">{{ $t('common.purchase') }}</el-radio>
                            <el-radio label="0">{{ $t('common.sellout') }}</el-radio>
                        </el-radio-group>
                        <div class="bottom">
                            <div class="g_btn btn bigbtn" @click="submitOrderCalculate(2)">
                                {{ $t('common.submit') }}</div>
                        </div>
                    </div>
                    <div v-else-if="selectTool == 'empower'">
                        <div class="title">{{ $t('stocks.token') }}</div>
                        <div class="inp_box">
                            <input v-model="toolform.token" controls-position="right"
                                :placeholder="$t('stocks.tokenName')" class="inp chain" />
                        </div>
                        <!-- 钱包分组 -->
                        <div class="title">{{$t('robot.walletGroup')}}</div>
                        <div class="inp_box">
                            <el-select class="inp" v-model="toolform.group_id" :placeholder="$t('common.select')">
                                <el-option v-for="(group,index) in walletGroupList" :label="group.name"
                                    :value="group.id" :key="index">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div v-else-if="selectTool == 'addToken'">
                        <div class="title">{{ $t('common.exchange') }}</div>
                        <div class="inp_box select" @click="openPopAddToken">
                            <input v-model="addTokenExchange" type="text" :placeholder="$t('common.select')" readonly
                                class="inp chain" />
                            <i class="iconfont icon-down"></i>
                        </div>
                        <div class="title">{{ $t('tools.token') }}</div>
                        <div class="inp_box">
                            <input v-model="toolform.token" controls-position="right" :placeholder="$t('tools.tokenPH')"
                                class="inp chain" />
                        </div>
                        <div class="title">{{ $t('tools.address') }}</div>
                        <div class="inp_box">
                            <input v-model="toolform.address" controls-position="right"
                                :placeholder="$t('tools.address')" class="inp chain" />
                        </div>
                        <div class="title">{{ $t('tools.decimals') }}</div>
                        <div class="inp_box">
                            <input v-model="toolform.decimal_num" controls-position="right"
                                @input="inputHandler2($event, 'toolform', 'decimal_num')"
                                :placeholder="$t('tools.decimals')" class="inp chain" />
                        </div>
                        <template v-if="addTokenWeb.includes('uniswapv3')">
                            <div class="title">{{ $t('tools.fee') }}</div>
                            <div class="inp_box">
                                <input v-model="toolform.fee" controls-position="right"
                                    @input="inputHandler($event, 'toolform', 'fee')" :placeholder="$t('tools.fee')"
                                    class="inp chain" />
                            </div>
                        </template>
                        <div class="title">{{ $t('tools.addPair') }}</div>
                        <div class="inp_box2">
                            <el-switch active-color="#E65B1B" inactive-color="#e9e9e9" :active-value="1"
                                :inactive-value="0" v-model="showPair">
                            </el-switch>
                        </div>
                        <template v-if="showPair==1">
                            <div class="title">{{ $t('tools.quoteToken') }}</div>
                            <div class="inp_box">
                                <input v-model="toolform.token_currency" controls-position="right"
                                    :placeholder="$t('tools.quoteToken')" class="inp chain" />
                            </div>
                            <div class="title">{{ $t('tools.pairAddress') }}
                                <span v-if="addTokenWeb == 'raydiumsol'">(AMM ID)</span>
                            </div>
                            <div class="inp_box">
                                <input v-model="toolform.pair_address" controls-position="right"
                                    :placeholder="$t('tools.pairAddress')" class="inp chain" />
                            </div>
                        </template>
                    </div>
                    <!-- nft 批量下买单/卖单 -->
                    <!-- 交易数量、买单价格范围 -->
                    <div v-else-if="web == 'opensea' && (selectTool == 'check' || selectTool == 'sale')">
                        <div class="title">
                            {{ selectTool == 'check' ? $t('stocks.check') : $t('stocks.sale') }}
                        </div>
                        <div class="inp_box">
                            <el-input v-model="toolform.lowPrice"
                                @input.native="inputHandler($event, 'toolform', 'lowPrice')"
                                :placeholder="$t('common.minValue')" class="inp textCenter" />
                            <span class="g_to">{{ $t('common.to') }}</span>
                            <el-input v-model="toolform.highPrice"
                                @input.native="inputHandler($event, 'toolform', 'highPrice')"
                                :placeholder="$t('common.maxValue')" class="inp textCenter" />
                        </div>
                        <div class="title">{{ $t('stocks.quantity') }}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.lowNum"
                                @input.native="inputHandler($event, 'toolform', 'lowNum')"
                                :placeholder="$t('common.minValue')" class="inp textCenter" />
                            <span class="g_to">{{ $t('common.to') }}</span>
                            <el-input v-model="toolform.highNum"
                                @input.native="inputHandler($event, 'toolform', 'highNum')"
                                :placeholder="$t('common.maxValue')" class="inp textCenter" />
                        </div>
                        <!-- <div class="title">{{$t('stocks.total')}}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.totalNum"
                                @input.native="inputHandler2($event,'toolform','totalNum')"
                                :placeholder="$t('common.value')" class="inp" />
                        </div>
                        <div class="title">{{$t('stocks.decimal')}}</div>
                        <div class="inp_box">
                            <el-input v-model="toolform.decimal"
                                @input.native="inputHandler2($event,'toolform','decimal')"
                                :placeholder="$t('common.value')" class="inp" />
                        </div> -->
                    </div>
                    <!-- 钱包数据 添加token时不显示 -->
                    <div class="balanceData" v-if="selectTool != 'addToken'">
                        <template v-if="web != 'opensea'">
                            <!-- 资金钱包 -->
                            <div class="bigtitle">{{ $t('stocks.mainAssets') }}</div>
                            <div>
                                <div>
                                    <div class="title">{{ yenUnit1.toUpperCase() }} </div>
                                    <div class="money">
                                        <span class="green">
                                            {{ $t('common.available') }}
                                            {{ balanceFormat('balance1', 'balance') }}
                                        </span>
                                        <span class="red">
                                            {{ $t('common.frozen') }}
                                            {{ balanceFormat('balance1', 'freeze') }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="title">{{ yenUnit2.toUpperCase() }} </div>
                                    <div class="money">
                                        <span class="green">
                                            {{ $t('common.available') }}
                                            {{ balanceFormat('balance2', 'balance') }}
                                        </span>
                                        <span class="red">
                                            {{ $t('common.frozen') }}
                                            {{ balanceFormat('balance2', 'freeze') }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- 交易钱包 -->
                            <template v-if="Object.keys(subBalance).length > 0">
                                <div class="bigtitle">{{ $t('stocks.subAssets') }}</div>
                                <div>
                                    <div v-for="(item, key, index) in subBalance" :key="index">
                                        <div class="title">{{ key }} </div>
                                        <div class="money">
                                            <span class="green">{{ $t('common.available') }}
                                                {{ item ? Math.floor(item * 1000000) /
                                                1000000 : item }}</span>
                                            <span class="red">{{ $t('common.frozen') }}
                                                0</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <!-- opensea -->
                            <template v-if="Object.keys(balance1).length > 0">
                                <div class="bigtitle">{{ $t('stocks.mainAssets') }}</div>
                                <div>
                                    <div v-for="(item, key, index) in balance1" :key="index">
                                        <div class="title">{{ key }} </div>
                                        <div class="money">
                                            <span class="green">{{ $t('common.available') }}
                                                {{ item ? Math.floor(item * 1000000) /
                                                1000000 : item }}</span>
                                            <!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
                                            <span class="red">{{ $t('common.frozen') }}
                                                0</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="Object.keys(subBalance).length > 0">
                                <div class="bigtitle">{{ $t('stocks.subAssets') }}</div>
                                <div>
                                    <div v-for="(item, key, index) in subBalance" :key="index">
                                        <div class="title">{{ key }} </div>
                                        <div class="money">
                                            <span class="green">{{ $t('common.available') }}
                                                {{ item ? Math.floor(item * 1000000) /
                                                1000000 : item }}</span>
                                            <span class="red">{{ $t('common.frozen') }}
                                                0</span>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-if="Object.keys(balance2).length > 0">
                                <div class="bigtitle">{{ $t('stocks.mainAssetsNFT') }}
                                </div>
                                <div>
                                    <div v-for="(item, key, index) in balance2" :key="index">
                                        <div class="title">{{ key }} </div>
                                        <div class="money">
                                            <span class="green">{{ $t('common.available') }}
                                                {{ item ? Math.floor(item * 1000000) /
                                                1000000 : item }}</span>
                                            <span class="red">{{ $t('common.frozen') }}
                                                0</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="web == 'opensea' && Object.keys(subBalance2).length > 0">
                                <div class="bigtitle">{{ $t('stocks.subAssetsNFT') }}
                                </div>
                                <div>
                                    <div v-for="(item, key, index) in subBalance2" :key="index">
                                        <div class="title">{{ key }} </div>
                                        <div class="money">
                                            <span class="green">{{ $t('common.available') }}
                                                {{ item ? Math.floor(item * 1000000) /
                                                1000000 : item }}</span>
                                            <span class="red">{{ $t('common.frozen') }}
                                                0</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>

                    </div>
                    <div class="bottom" v-if="selectTool != 'calculate'">
                        <div class="g_btn btn save" @click="submitSave"
                            v-if="web != 'opensea' && (selectTool == 'check' || selectTool == 'sale')">
                            {{ $t('common.save') }}</div>
                        <div class="g_btn btn bigbtn" @click="submitOrder">
                            {{ $t('common.submit') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 手动下单 -->
        <div class="order_action_handle" v-else-if="tabIndex == 1">
            <div class="hd" v-if="web_type == 1">
                <div class="left">
                    {{ $t('tools.transaction.name') }}
                </div>
                <div class="right">
                    <div class="help" @click="handleHelp('transaction')">
                        <i class="iconfont icon-tips"></i>
                        {{ isMobile==0? $t('common.description'):'' }}
                    </div>
                </div>
            </div>
            <div class="action_form">
                <!-- cex -->
                <template v-if="web_type == 0">
                    <div class="title">{{ $t('common.pirce') }}</div>
                    <div class="inp_box">
                        <el-input v-model="form.price" @input.native="priceChange" :placeholder="$t('common.value')"
                            class="inp" />
                    </div>
                    <div class="title">{{ $t('common.num') }}</div>
                    <div class="inp_box">
                        <el-input v-model="form.number" @input.native="numberChange" :placeholder="$t('common.value')"
                            class="inp" />
                    </div>
                    <!-- 金额 -->
                    <div class="title">{{ $t('stocks.amount') }}</div>
                    <div class="inp_box">
                        <el-input v-model="form.total" @input.native="totalChange" :placeholder="$t('common.value')"
                            class="inp" />
                        <span class="company green">{{ yenUnit2 }}</span>
                    </div>
                </template>
                <template v-else>
                    <div class="title">{{ $t('stocks.strategy') }}</div>
                    <div class="radio_box">
                        <el-radio v-model="form.strategy" label="1">
                            {{ $t('stocks.strategyDefault') }}</el-radio>
                        <el-radio v-model="form.strategy" label="2">
                            {{ $t('stocks.strategyCondition') }}</el-radio>
                    </div>

                    <div class="title" v-if="form.strategy == 2">
                        {{ $t('stocks.walletsNumber') }}</div>
                    <div class="inp_box" v-if="form.strategy == 2">
                        <el-input v-model="form.walletsNumber"
                            @input.native="inputHandler2($event, 'form', 'walletsNumber', 1)"
                            :placeholder="$t('common.value')" class="inp emptyVer" />
                        <span class="company"></span>
                    </div>

                    <div class="title">{{ $t('stocks.volume') }}</div>
                    <div class="inp_box">
                        <el-input v-model="form.volume" @input.native="volumeChangeDex"
                            :placeholder="$t('common.value')" class="inp emptyVer" />
                        <span class="company" :class="form.transactionType == 1 ? 'green' : 'red'">{{
                            !form.transactionType ? '' : form.transactionType == 1 ? yenUnit2 : yenUnit1 }}</span>
                    </div>

                    <div class="title" v-if="form.strategy == 2">
                        {{ $t('stocks.floatingRange') }}</div>
                    <div class="inp_box" v-if="form.strategy == 2" id="float">
                        <el-input @input.native="floatChange" v-model="form.floatingRange"
                            :placeholder="$t('common.value')" class="inp emptyVer" />
                        <span class="company">%</span>
                    </div>
                    <div class="tip" v-if="is0">{{ $t('stocks.floatTip0') }}</div>

                    <div class="title">{{ $t('stocks.transactionType') }}</div>
                    <el-radio-group class="radio_box" v-model="form.transactionType" @change="transactionTypeChange">
                        <el-radio label="1">{{ $t('stocks.buy') }}</el-radio>
                        <el-radio label="0">{{ $t('stocks.sell') }}</el-radio>
                    </el-radio-group>
                    <!-- 金额dex -->
                    <div class="title">{{ $t('stocks.amount') }}</div>
                    <div class="inp_box">
                        <el-input v-model="form.totalDex" @input.native="totalChangeDex"
                            :placeholder="$t('common.value')" class="inp" />
                        <span class="company green">{{ yenUnit2 }}</span>
                    </div>
                    <!-- 下单限额 -->
                    <div class="title">{{ $t('stocks.orderLimit') }}</div>
                    <div class="inp_box">
                        <el-input v-model="form.orderLimit" @input.native="inputHandler($event, 'form', 'orderLimit')"
                            :placeholder="$t('common.valueOptional')" class="inp" />
                        <span class="company green">{{ yenUnit2 }}</span>
                    </div>
                    <!-- 间隔时间 -->
                    <div class="title" v-if="form.strategy == 2">{{ $t('stocks.intervalTime') }}</div>
                    <div class="inp_box" v-if="form.strategy == 2">
                        <el-input v-model="form.intervalTime"
                            @input.native="inputHandler($event, 'form', 'intervalTime')"
                            :placeholder="$t('common.valueOptional')" class="inp" />
                        <span class="company">{{ $t('common.second') }}</span>
                    </div>
                    <!-- gas倍数 -->
                    <div class="title" v-if="solWebList.indexOf(web) !== -1">
                        {{ $t('robot.gasMultiplier') }}
                        <span>( {{$t('robot.gasMultiplierTip')}} )</span>
                    </div>
                    <div class="inp_box " v-if="solWebList.indexOf(web) !== -1">
                        <el-input v-model="form.gas_rate" @input.native="inputHandler($event, 'form', 'gas_rate')"
                            :placeholder="$t('common.value')" class="inp emptyVer" />
                        <span class="company">{{$t('robot.times')}}</span>
                    </div>
                    <!-- 滑点 -->
                    <div class="title">
                        {{ $t('common.slippage') }}
                    </div>
                    <div class="inp_box inp_box3">
                        <!-- <el-input v-model="form.slip_rate"
                            @input.native="inputHandler($event, 'form', 'slip_rate')"
                            placeholder="0.1% ~ 99.9%" class="inp emptyVer" />
                        <span class="company">%</span> -->
                        <el-slider v-model="form.slip_rate" :min="0.1" :max="99.9" :step="0.1" show-input>
                        </el-slider>
                        <span class="company">%</span>
                    </div>
                    <!-- 钱包分组 -->
                    <div class="title">{{$t('robot.walletGroup')}}</div>
                    <div class="inp_box">
                        <el-select class="inp" v-model="form.group_id" :placeholder="$t('common.select')">
                            <el-option v-for="(group,index) in walletGroupList" :label="group.name" :value="group.id"
                                :key="index">
                            </el-option>
                        </el-select>
                    </div>
                </template>

                <div class="balanceData">
                    <template v-if="web != 'opensea'">
                        <!-- 资金钱包 -->
                        <div class="bigtitle">{{ $t('stocks.mainAssets') }}</div>
                        <div>
                            <div>
                                <div class="title">{{ yenUnit1.toUpperCase() }} </div>
                                <div class="money">
                                    <span class="green">
                                        {{ $t('common.available') }}
                                        {{ balanceFormat('balance1', 'balance') }}
                                    </span>
                                    <span class="red">
                                        {{ $t('common.frozen') }}
                                        {{ balanceFormat('balance1', 'freeze') }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="title">{{ yenUnit2.toUpperCase() }} </div>
                                <div class="money">
                                    <span class="green">
                                        {{ $t('common.available') }}
                                        {{ balanceFormat('balance2', 'balance') }}
                                    </span>
                                    <span class="red">
                                        {{ $t('common.frozen') }}
                                        {{ balanceFormat('balance2', 'freeze') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- 交易钱包 -->
                        <template v-if="Object.keys(subBalance).length > 0">
                            <div class="bigtitle">{{ $t('stocks.subAssets') }}</div>
                            <div>
                                <div v-for="(item, key, index) in subBalance" :key="index">
                                    <div class="title">{{ key }} </div>
                                    <div class="money">
                                        <span class="green">{{ $t('common.available') }}
                                            {{ item ? Math.floor(item * 1000000) /
                                            1000000 : item }}</span>
                                        <span class="red">{{ $t('common.frozen') }} 0</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <!-- opensea -->
                        <template v-if="Object.keys(balance1).length > 0">
                            <div class="bigtitle">{{ $t('stocks.mainAssets') }}</div>
                            <div>
                                <div v-for="(item, key, index) in balance1" :key="index">
                                    <div class="title">{{ key }} </div>
                                    <div class="money">
                                        <span class="green">{{ $t('common.available') }}
                                            {{ item ? Math.floor(item * 1000000) /
                                            1000000 : item }}</span>
                                        <span class="red">{{ $t('common.frozen') }} 0</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="Object.keys(subBalance).length > 0">
                            <div class="bigtitle">{{ $t('stocks.subAssets') }}</div>
                            <div>
                                <div v-for="(item, key, index) in subBalance" :key="index">
                                    <div class="title">{{ key }} </div>
                                    <div class="money">
                                        <span class="green">{{ $t('common.available') }}
                                            {{ item ? Math.floor(item * 1000000) /
                                            1000000 : item }}</span>
                                        <span class="red">{{ $t('common.frozen') }} 0</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="Object.keys(balance2).length > 0">
                            <div class="bigtitle">{{ $t('stocks.mainAssetsNFT') }}</div>
                            <div>
                                <div v-for="(item, key, index) in balance2" :key="index">
                                    <div class="title">{{ key }} </div>
                                    <div class="money">
                                        <span class="green">{{ $t('common.available') }}
                                            {{ item ? Math.floor(item * 1000000) /
                                            1000000 : item }}</span>
                                        <span class="red">{{ $t('common.frozen') }} 0</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="web == 'opensea' && Object.keys(subBalance2).length > 0">
                            <div class="bigtitle">{{ $t('stocks.subAssetsNFT') }}</div>
                            <div>
                                <div v-for="(item, key, index) in subBalance2" :key="index">
                                    <div class="title">{{ key }} </div>
                                    <div class="money">
                                        <span class="green">{{ $t('common.available') }}
                                            {{ item ? Math.floor(item * 1000000) /
                                            1000000 : item }}</span>
                                        <span class="red">{{ $t('common.frozen') }} 0</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="bottom" v-if="web_type == 0">
                    <div class="g_btn btn" @click="handleOrder(1)">{{ $t('common.purchase') }}
                    </div>
                    <div class="g_btn btn red" @click="handleOrder(0)">
                        {{ $t('common.sellout') }}</div>
                </div>
                <div class="bottom" v-else>
                    <div class="g_btn btn bigbtn" @click="handleOrder2">
                        {{ $t('common.submit') }}</div>
                </div>
            </div>
        </div>

        <!-- 功能说明弹窗 -->
        <el-dialog class="w900" :title="explainTool ? $t('tools.' + explainTool + '.name') + $t('common.explain') : ''"
            center :visible.sync="showHelp" :show-close="false">
            <div class="tips_form" v-html="explainTool ? $t('tools.' + explainTool + '.html') : ''"></div>
        </el-dialog>
        <!-- 移动端-量化工具选择-动作面板 -->
        <van-action-sheet v-model="tool_box_show" :title="$t('stocks.toolSelect')" :closeable="false">
            <div class="toolList">
                <template v-if="web_type == 0">
                    <!-- cex量化工具 -->
                    <div class="item" v-for="(item, index) in toolList" :key="index"
                        @click="handleCreate(item, type >= 2)">
                        <div class="left" :class="{ notOpen: type < 2 }">
                            <div class="title">
                                <span class="icon">
                                    <img :src="require('@/assets/images/' + item + '.png')" />
                                </span>
                                {{ $t('tools.' + item + '.name') }}
                            </div>
                            <div class="desc">{{ $t('tools.' + item + '.sub') }}</div>
                        </div>
                        <div class="right">
                            <div v-if="type >= 2" class="btn">
                                <div v-if="selectTool == item">
                                    <i class="iconfont2 icon-yes"></i>
                                </div>
                                <div v-else>
                                    {{ $t('common.into') }}
                                </div>
                            </div>
                            <div v-else class="btn">{{ $t('common.open') }}</div>
                        </div>
                    </div>
                </template>
                <template v-else-if="web_type == 1">
                    <!-- dex量化工具 -->
                    <div class="item" v-for="(item, index) in toolDexList" :key="'0' + index"
                        @click="handleCreate(item, type >= 4||item == 'manage')">
                        <div class="left" :class="{ notOpen: type < 4 && item != 'manage' }">
                            <div class="title">
                                <span class="icon">
                                    <img :src="require('@/assets/images/' + item + '.png')" />
                                </span>
                                {{ $t('tools.' + item + '.name') }}
                            </div>
                            <div class="desc">{{ $t('tools.' + item + '.sub') }}</div>
                        </div>
                        <div class="right">
                            <div v-if="type >= 4 || item == 'manage'" class="btn">
                                <div v-if="selectTool == item">
                                    <i class="iconfont2 icon-yes"></i>
                                </div>
                                <div v-else>
                                    {{ $t('common.into') }}
                                </div>
                            </div>
                            <div v-else class="btn">{{ $t('common.open') }}</div>
                        </div>
                    </div>
                </template>
                <template v-else-if="web_type == 2">
                    <!-- nft量化工具 -->
                    <div class="item" v-for="(item, index) in toolNFTList" :key="index"
                        @click="handleCreate(item, type >= 2)">
                        <div class="left" :class="{ notOpen: type < 4 }">
                            <div class="title">
                                <span class="icon">
                                    <img
                                        :src="require('@/assets/images/' + (item == 'manageNFT' ? 'manage' : item) + '.png')" />
                                </span>
                                {{ $t('tools.' + item + '.name') }}
                            </div>
                            <div class="desc">{{ $t('tools.' + item + '.sub') }}</div>
                        </div>
                        <div class="right">
                            <div v-if="type >= 2" class="btn">
                                <div v-if="selectTool == item">
                                    <i class="iconfont2 icon-yes"></i>
                                </div>
                                <div v-else>
                                    {{ $t('common.into') }}
                                </div>
                            </div>
                            <div v-else class="btn">{{ $t('common.open') }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </van-action-sheet>
    </div>
</template>
<script>
    // api
    import {
        tradeCoin,
        batchTradeCoin,
        batchTradeCancel,

        calNumberDex,
        batchTradeDex,
        dexWalletsApprove,
        addToken,
    } from '@/api/markets'

    import { mapGetters } from 'vuex'
    import Decimal from 'decimal.js'
    export default ({
        computed: {
            ...mapGetters(['type', 'account', 'isMobile', 'solWebList']),
            is0() {
                return (this.form.strategy == 2) && (!this.form.floatingRange || this.form.floatingRange == 0)
            },
        },
        props: [
            'web',
            'web_type',
            'yenUnit1',
            'yenUnit2',
            'addTokenWeb',
            'addTokenExchange',
            'balance1',
            'balance2',
            'subBalance',
            'subBalance2',
            'bidsList',
            'asksList',
            'walletGroupList',
        ],
        data() {
            return {
                tabIndex: 1,

                // 量化工具
                toolList: ['check', 'sale', 'revoke'],
                toolDexList: ['manage', 'calculate', 'empower', 'addToken'],
                toolNFTList: ['manageNFT', 'check', 'sale'],
                selectTool: 'check',

                form: {},//手动下单数据
                toolform: {},//量化工具数据

                isTool: false,
                // 说明
                showHelp: false,
                explainTool: 'check',
                showPair: 0,
                // 移动端
                tool_box_show: false,
            };
        },
        methods: {
            // 选中普通盘口 快捷填入数据-手动下单-cex
            selectData(price, number, total) {
                this.$set(this.form, 'price', price)
                this.$set(this.form, 'number', number)
                this.$set(this.form, 'total', total)
            },
            // 每次选择盘口时初始化
            init() {
                // 手动下单数据初始化
                this.$set(this, 'form', [])
                if (this.tabIndex == 1 && !this.form.strategy && this.web_type == 1) {// dex交易策略
                    this.$set(this.form, 'strategy', '1')
                }
                if (this.tabIndex == 1 && !this.form.gas_rate && (this.solWebList.indexOf(this.web) !== -1)) {// gas倍数
                    this.$set(this.form, 'gas_rate', 1)
                }

                // 如果dex手动下单
                if (this.tabIndex == 1 && this.web_type == 1) {
                    // dex自定义默认滑点
                    let slipRateStr = localStorage.getItem('slipRateList')
                    if (!slipRateStr) {
                        var hasWebSlipRate = false
                    } else {
                        var slipRateList = JSON.parse(slipRateStr)
                        var hasWebSlipRate = !!slipRateList[this.web]
                    }

                    if (!hasWebSlipRate) {
                        if (this.solWebList.indexOf(this.web) !== -1) {
                            this.$set(this.form, 'slip_rate', 0.15)
                        }
                        else if (this.web == 'kadswap') {
                            this.$set(this.form, 'slip_rate', 1.7)
                        }
                        else {
                            this.$set(this.form, 'slip_rate', 0.5)
                        }
                    } else {
                        this.$set(this.form, 'slip_rate', slipRateList[this.web]);
                    }

                    // 钱包分组
                    this.$set(this.form, 'group_id', '-999');
                }

                // 量化工具数据初始化
                this.$set(this, 'toolform', [])
                this.showPair = 0
                this.initToolFormData()

                // dex回显交易限额
                let orderLimit = localStorage.getItem('orderLimit')
                this.$set(this.form, 'orderLimit', orderLimit);

                // 选择默认工具
                this.isTool = false;

                if (this.web != 'opensea') {
                    this.selectTool = this.web_type == 0 ? 'check' : 'calculate';
                } else {
                    this.selectTool = 'check';
                }
            },
            switchToolTab(num) {
                this.tabIndex = num
                if (this.tabIndex == 1 && !this.form.strategy && this.web_type == 1) {
                    this.$set(this.form, 'strategy', '1')
                }
            },

            // 量化工具-进入/选择
            handleCreate(item, power) {
                if (power) {
                    if (item == 'manage') {// 进入交易钱包管理页面
                        this.$router.push({
                            path: "/subManage",
                            query: {
                                web: this.web,
                                yenUnit1: this.yenUnit1,
                                yenUnit2: this.yenUnit2,
                            }
                        })
                        this.tool_box_show = false
                        return
                    }

                    this.isTool = true;
                    this.selectTool = item;
                    this.toolform = {};
                    if (item == 'addToken') {// 添加token合约
                        this.$emit('update:addTokenExchange', '')
                        this.$emit('update:addTokenWeb', '')
                        this.showPair = 0
                        // 默认值3000
                        this.toolform = {
                            fee: 3000,
                            token_currency: this.solWebList.indexOf(this.web) !== -1 ? 'SOL' : 'USDT',
                        }
                    }
                    this.tool_box_show = false

                    this.initToolFormData()
                } else {// 量化工具-开通
                    this.$router.push({
                        path: '/renew'
                    })
                    // this.$message({
                    //     message: this.$t('tip.renewTip'),
                    //     type: 'info',
                    //     center: true,
                    //     customClass: 'shotMsg',
                    // });
                    this.tool_box_show = false
                }
            },
            // 量化工具说明
            handleHelp(typeName) {
                this.showHelp = true
                this.explainTool = typeName == 'transaction' ? 'transaction' : this.selectTool
            },
            // 初始化存储的表单数据-量化工具-cex批量下买单、批量下卖单
            initToolFormData() {
                if (this.web_type != 0) return

                if (this.selectTool == 'check') {
                    var toolformStr = localStorage.getItem('cex_order_tool_data_check')
                } else if (this.selectTool == 'sale') {
                    var toolformStr = localStorage.getItem('cex_order_tool_data_sale')
                }
                if (toolformStr) {
                    this.toolform = JSON.parse(toolformStr)
                }

            },
            // 添加token合约选择交易所
            openPopAddToken() {
                this.$emit('openPopAddToken')
            },

            // 量化工具-保存-cex批量下买单、批量下卖单
            submitSave() {
                var toolform = {
                    lowPrice: this.toolform.lowPrice,
                    highPrice: this.toolform.highPrice,
                    lowNum: this.toolform.lowNum,
                    highNum: this.toolform.highNum,
                    totalNum: this.toolform.totalNum,
                    decimal: this.toolform.decimal,
                    quantityDecimal: this.toolform.quantityDecimal,
                }
                var toolformStr = JSON.stringify(toolform)

                if (this.selectTool == 'check') {
                    localStorage.setItem('cex_order_tool_data_check', toolformStr)
                } else if (this.selectTool == 'sale') {
                    localStorage.setItem('cex_order_tool_data_sale', toolformStr)
                } else {
                    return
                }
                this.$message({
                    message: this.$t('tip.saveSuc'),
                    center: true,
                    type: 'success',
                    customClass: 'shotMsg',
                });
            },
            // token符号查询给错误提示 并去空
            tokenFormat(token, labelType) {
                if (token.includes('_') || token.includes('—') || token.includes('/') || token.includes('\\')) {
                    this.$nextTick(() => {
                        this.$message({
                            message: this.$t('tools.formatErr', { token: labelType }),
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    })
                    throw new Error()
                } else {
                    const trimmedString = token.trim();
                    return trimmedString; // 如果需要返回去掉前后空格的字符串
                }
            },
            // 量化工具-提交
            submitOrder() {
                var inputList = document.querySelectorAll('.order_action_tool .action_form input')
                var empty = false;
                try {
                    inputList.forEach(item => {
                        if (!item.value) {
                            throw ('err')
                        }
                    })
                } catch {
                    empty = true;
                }
                // 非空
                if (empty) {
                    this.$message({
                        message: this.$t('tip.dataErr'),
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else {
                    // 二次确认
                    this.$confirm(this.$t('common.confirm') + this.$t('tools.' + this.selectTool + '.name') + '？', this.$t('tip.tips'), {
                        confirmButtonText: this.$t('common.confirm'),
                        cancelButtonText: this.$t('common.back'),
                        center: true,
                        showClose: false,
                        customClass: 'deleteOk',
                    }).then((res) => {
                        // console.log(this.toolform);
                        var currency
                        if (this.web != 'opensea') {
                            currency = this.yenUnit1 + '_' + this.yenUnit2
                        } else {
                            currency = this.yenUnit1
                        }

                        if (this.web != 'opensea') {
                            if (this.selectTool == 'check' || this.selectTool == 'sale') {
                                if (this.toolform.totalNum > 500) {
                                    this.$message({
                                        message: this.$t('tip.total500'),
                                        type: 'error',
                                        center: true,
                                        customClass: 'shotMsg',
                                    });
                                } else {
                                    this.$message({
                                        message: this.$t('tip.processing'),
                                        type: 'success',
                                        center: true,
                                        customClass: 'shotMsg',
                                    });
                                    // 批量下买单/卖单
                                    batchTradeCoin({
                                        web: this.web,
                                        account: this.account,
                                        currency,
                                        price_low: this.toolform.lowPrice,
                                        price_high: this.toolform.highPrice,
                                        num_low: this.toolform.lowNum,
                                        num_high: this.toolform.highNum,
                                        order_num: this.toolform.totalNum,
                                        price_div: this.toolform.decimal,
                                        num_div: this.toolform.quantityDecimal,
                                        side: this.selectTool == 'check' ? 'bids' : 'asks',
                                    }).then(res => {
                                        this.$message({
                                            message: this.$t('tip.operationSuc'),
                                            type: 'success',
                                            center: true,
                                            customClass: 'shotMsg',
                                        });
                                        // 获取新数据 重置轮询 资金、订单、盘口相关
                                        this.$emit('updateData')
                                    })
                                }
                            } else if (this.selectTool == 'revoke') {
                                this.$message({
                                    message: this.$t('tip.processing'),
                                    type: 'success',
                                    center: true,
                                    customClass: 'shotMsg',
                                });
                                // 批量撤单
                                batchTradeCancel({
                                    web: this.web,
                                    account: this.account,
                                    currency,
                                    price_low: this.toolform.lowPrice,
                                    price_high: this.toolform.highPrice,
                                    num_low: this.toolform.lowNum,
                                    num_high: this.toolform.highNum,
                                }).then(res => {
                                    this.$message({
                                        message: this.$t('tip.operationSuc'),
                                        type: 'success',
                                        center: true,
                                        customClass: 'shotMsg',
                                    });
                                    // 获取新数据 重置轮询 资金、订单、盘口相关
                                    this.$emit('updateData')
                                })
                            } else if (this.selectTool == 'empower') {
                                if (this.toolform.token.length >= 20) {
                                    this.$message({
                                        message: this.$t('stocks.tokenNameTip'),
                                        type: 'error',
                                        center: true,
                                        customClass: 'shotMsg',
                                    });
                                    return
                                }
                                try {
                                    var approve_token = this.tokenFormat(this.toolform.token, this.$t('stocks.token'));
                                }
                                catch (err) {
                                    return
                                }

                                this.$message({
                                    message: this.$t('tip.processing'),
                                    type: 'success',
                                    center: true,
                                    customClass: 'shotMsg',
                                });
                                // Dex钱包授权
                                dexWalletsApprove({
                                    web: this.web,
                                    account: this.account,
                                    approve_token,
                                    is_master_approve: 2,
                                    approve_source: 1,
                                    group_id: this.toolform.group_id,
                                }).then(res => {
                                    // console.log(res,'dexWalletsApprove')
                                    if (res.code == 2173) {
                                        // 分拨授权
                                        this.$emit('openSubTransfer', {
                                            address_list: res.data.address_list,
                                            id_list: res.data.id_list,
                                            chain: res.data.chain,
                                            token: res.data.gas_token,
                                            approve_token: res.data.approve_token,
                                            transferWeb: this.web,
                                            transferExchange: this.exchange,
                                        })
                                    } else {
                                        // 授权成功
                                        this.$confirm(this.$t('tip.authorizeSuc1'), this.$t('tip.tips'), {
                                            confirmButtonText: this.$t('common.confirm'),//'继续操作',
                                            // showConfirmButton:false,
                                            center: true,
                                            showClose: false,
                                            customClass: 'deleteOk',
                                            // cancelButtonText: this.$t('common.back'),
                                            showCancelButton: false,
                                        })
                                    }
                                })
                            } else if (this.selectTool == 'addToken') {
                                try {
                                    var token = this.tokenFormat(this.toolform.token, this.$t('tools.token'));
                                    var token_currency = this.showPair == 1 ? token + '_' + this.tokenFormat(this.toolform.token_currency, this.$t('tools.quoteToken')) : '';
                                }
                                catch (err) {
                                    return
                                }

                                this.$message({
                                    message: this.$t('tip.processing'),
                                    type: 'success',
                                    center: true,
                                    customClass: 'shotMsg',
                                });
                                addToken({
                                    web: this.addTokenWeb,
                                    token,
                                    address: this.toolform.address,
                                    decimal_num: this.toolform.decimal_num,
                                    fee: this.addTokenWeb.includes('uniswapv3') ? this.toolform.fee : null,
                                    account: this.account,
                                    token_currency,
                                    pair_address: this.showPair == 1 ? this.toolform.pair_address : '',
                                }).then(res => {
                                    this.$confirm(`
										${this.$t('tip.addSuc')}<br/><br/>
									<p style="text-align:left;display:flex;justify-content: center;">
										${this.$t('common.exchange')}: ${res.web}<br/>
										${this.$t('subManage.chain')}: ${res.chain}<br/>
										${this.$t('tools.token')}: ${res.token}<br/>
										${this.$t('tools.address')}: ${res.address}<br/>
										${this.$t('tools.decimals')}: ${res.decimal_num}<br/>
										${res.web.includes('uniswapv3') ? this.$t('tools.fee') + ': ' + res.fee : ''}
									</p>
									`, this.$t('tip.tips'), {
                                        confirmButtonText: this.$t('common.confirm'),
                                        // showConfirmButton:false,
                                        center: true,
                                        showClose: false,
                                        customClass: 'deleteOk',
                                        // cancelButtonText: this.$t('common.back'),
                                        showCancelButton: false,
                                        dangerouslyUseHTMLString: true
                                    })
                                })
                            }
                        } else {
                            // opensea 敬请期待
                            this.$message({
                                message: this.$t('tip.waiting'),
                                center: true,
                                customClass: 'shotMsg',
                            });
                        }
                    })
                }
            },
            // 量化工具-dex交易量测算-提交
            submitOrderCalculate(calculateType) {
                // 非空
                if (
                    (calculateType == 1 && !this.toolform.target) ||
                    (calculateType == 2 && (!this.toolform.targetNum || !this.toolform.tradeType))
                ) {
                    this.$message({
                        message: this.$t('tip.dataErr'),
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else {
                    // 二次确认
                    this.$confirm(this.$t('common.confirm') + this.$t('tools.' + this.selectTool + '.name') + '？', this.$t('tip.tips'), {
                        confirmButtonText: this.$t('common.confirm'),
                        cancelButtonText: this.$t('common.back'),
                        center: true,
                        showClose: false,
                        customClass: 'deleteOk',
                    }).then((res) => {
                        var currency
                        if (this.web != 'opensea') {
                            currency = this.yenUnit1 + '_' + this.yenUnit2

                            if (this.selectTool == 'calculate') {
                                this.$message({
                                    message: this.$t('tip.processing'),
                                    type: 'success',
                                    center: true,
                                    customClass: 'shotMsg',
                                });
                                let reqData = {
                                    web: this.web,
                                    currency,
                                    account: this.account,
                                }
                                if (calculateType == 1) {
                                    reqData['target_price'] = this.toolform.target
                                    reqData['target_num'] = 0
                                    reqData['trade_type'] = 0
                                } else {
                                    reqData['target_price'] = 0
                                    reqData['target_num'] = this.toolform.targetNum
                                    reqData['trade_type'] = this.toolform.tradeType
                                }
                                // Dex交易量测算
                                calNumberDex(reqData).then(res => {
                                    // console.log(res,'calNumberDex')
                                    var msg
                                    if (calculateType == 1) {
                                        if (res['money_num'] >= 0) {
                                            var num1 = Math.abs(res['target_num']) + ' ' + this.yenUnit1.toUpperCase()
                                            var num2 = Math.abs(res['money_num']) + ' ' + this.yenUnit2.toUpperCase()
                                            // 需卖出
                                            msg = this.$t('tip.needSell', { num1: num1, num2: num2 })
                                        } else {
                                            var num1 = Math.abs(res['money_num']) + ' ' + this.yenUnit2.toUpperCase()
                                            var num2 = Math.abs(res['target_num']) + ' ' + this.yenUnit1.toUpperCase()
                                            msg = this.$t('tip.needBuy', { num1: num1, num2: num2 })
                                        }
                                    } else {
                                        var slipRate = new Decimal(1).minus(new Decimal(res.slip_rate)).times(100).toString() + '%'
                                        msg = this.$t('tip.calculationRes2', { num1: res.target_price, num2: slipRate })
                                    }
                                    this.$confirm(this.$t('tip.calculationSuc') + msg, this.$t('tip.tips'), {
                                        confirmButtonText: this.$t('common.confirm'),
                                        center: true,
                                        showClose: false,
                                        customClass: 'deleteOk',
                                        showCancelButton: false,
                                    });
                                })
                            }
                        } else {
                            // currency = this.yenUnit1
                            // opensea 敬请期待
                            this.$message({
                                message: this.$t('tip.waiting'),
                                center: true,
                                customClass: 'shotMsg',
                            });
                        }
                    })
                }
            },

            // 手动下单-买入/卖出
            handleOrder(trade_type) {
                // console.log(this.form);
                // 非空验证
                if (!this.form.number) {
                    this.$message({
                        message: this.$t('tip.numEmpty'),
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else if (!this.form.price) {
                    this.$message({
                        message: this.$t('tip.priceEmpty'),
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else {
                    // 二次确认
                    this.$confirm(this.$t('tip.' + (trade_type == 1 ? 'manuallyBuy' : 'manuallySell')), this.$t('tip.tips'), {
                        confirmButtonText: this.$t('common.confirm'),
                        cancelButtonText: this.$t('common.back'),
                        center: true,
                        showClose: false,
                        customClass: 'deleteOk',
                    }).then(() => {
                        // console.log(this.toolform);
                        if (this.web != 'opensea') {
                            this.$message({
                                message: this.$t('tip.processing'),
                                type: 'success',
                                center: true,
                                customClass: 'shotMsg',
                            });
                            var currency
                            if (this.web != 'opensea') {
                                currency = this.yenUnit1 + '_' + this.yenUnit2;
                            } else {
                                currency = this.yenUnit1;
                            }
                            tradeCoin({
                                web: this.web,
                                account: this.account,
                                currency,
                                num: this.form.number,
                                price: this.form.price,
                                trade_type,
                                approve_type: '1',
                            }).then(res => {
                                if (trade_type == 1) {
                                    // 买入成功
                                    this.$message({
                                        message: this.$t('tip.buySuc'),
                                        type: 'success',
                                        center: true,
                                        customClass: 'shotMsg',
                                    });
                                } else {
                                    // 卖出成功
                                    this.$message({
                                        message: this.$t('tip.sellSuc'),
                                        type: 'success',
                                        center: true,
                                        customClass: 'shotMsg',
                                    });
                                }
                                // 获取新数据 重置轮询 资金、订单、盘口相关
                                this.$emit('updateData')
                                this.form = {};
                            })
                        } else {
                            // opensea 敬请期待
                            this.$message({
                                message: this.$t('tip.waiting'),
                                center: true,
                                customClass: 'shotMsg',
                            });
                        }
                    })
                }
            },
            // Dex批量交易-手动下单
            handleOrder2(approve_type) {
                var inputList = document.querySelectorAll('.order_action_handle .action_form .emptyVer input')
                var empty = false;
                try {
                    inputList.forEach(item => {
                        if (!item.value) {
                            throw ('err')
                        }
                    })
                } catch {
                    empty = true;
                }
                // 非空
                if (empty || !this.form.transactionType || !this.form.strategy) {
                    this.$message({
                        message: this.$t('tip.dataErr'),
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                } else {
                    // 缓存滑点的值
                    var slipRateStr = localStorage.getItem('slipRateList')
                    var slipRateList = {}
                    if (!!slipRateStr) {
                        slipRateList = JSON.parse(slipRateStr)
                    }
                    slipRateList[this.web] = this.form.slip_rate
                    localStorage.setItem('slipRateList', JSON.stringify(slipRateList))

                    // 缓存交易限额的值
                    localStorage.setItem('orderLimit', this.form.orderLimit || '')
                    let orderLimit = Number(this.form.orderLimit)
                    // 如果金额超过交易限额，则提示下单超过限额
                    if (orderLimit > 0) {
                        if (this.form.totalDex > 0) {
                            if (this.form.totalDex > orderLimit) {
                                this.$message({
                                    message: this.$t('stocks.exceedsLimit'),
                                    type: 'error',
                                    center: true,
                                    customClass: 'shotMsg',
                                });
                                return
                            }
                        } else {
                            // 计算交易金额
                            let totalDex;
                            if (this.form.transactionType == 1) {
                                totalDex = this.form.volume
                            } else {
                                totalDex = Number(new Decimal(this.form.volume).mul(new Decimal(this.asksList[0].price)))
                            }

                            if (totalDex > orderLimit) {
                                this.$message({
                                    message: this.$t('stocks.exceedsLimit'),
                                    type: 'error',
                                    center: true,
                                    customClass: 'shotMsg',
                                });
                                return
                            }
                        }
                    }

                    if (this.form.transactionType == 1) {
                        var token1 = this.yenUnit2
                        var token2 = this.yenUnit1
                        var getMoney = new Decimal(this.form.volume).div(new Decimal(this.asksList[0].price)).toString()
                    } else {
                        var token1 = this.yenUnit1
                        var token2 = this.yenUnit2
                        var getMoney = new Decimal(this.form.volume).mul(new Decimal(this.bidsList[0].price)).toString()
                    }
                    // 二次确认
                    this.$confirm(this.$t('stocks.dexTransactionRes', { volume: this.form.volume, token1, getMoney, token2 }), this.$t('tip.tips'), {
                        // this.$confirm(this.$t('common.confirm') + this.$t('tools.transaction.name') + '？', this.$t('tip.tips'), {
                        confirmButtonText: this.$t('common.confirm'),
                        cancelButtonText: this.$t('common.back'),
                        center: true,
                        showClose: false,
                        customClass: 'deleteOk',
                    }).then((res) => {
                        var currency
                        if (this.web != 'opensea') {
                            currency = this.yenUnit1 + '_' + this.yenUnit2;
                        } else {
                            currency = this.yenUnit1;
                        }

                        this.$message({
                            message: this.$t('tip.processing'),
                            type: 'success',
                            center: true,
                            customClass: 'shotMsg',
                        });
                        if (this.form.strategy == 1) {// 交易策略-系统默认
                            var data = {
                                web: this.web,
                                currency,
                                account: this.account,
                                trade_amount: this.form.volume,
                                trade_type: this.form.transactionType,
                                approve_type: approve_type == 'again' ? '0' : '1',
                                strategy: this.form.strategy,
                                gas_rate: this.form.gas_rate || 0,
                                slip_rate: Number(new Decimal(1).minus(new Decimal(this.form.slip_rate).div(100))),
                                group_id: this.form.group_id,
                            }
                        } else {// 交易策略-条件交易
                            var data = {
                                web: this.web,
                                currency,
                                account: this.account,
                                trade_amount: this.form.volume,
                                trade_type: this.form.transactionType,
                                approve_type: approve_type == 'again' ? '0' : '1',
                                strategy: this.form.strategy,
                                gas_rate: this.form.gas_rate || 0,
                                slip_rate: Number(new Decimal(1).minus(new Decimal(this.form.slip_rate).div(100))),
                                group_id: this.form.group_id,

                                trade_wallet: this.form.walletsNumber,
                                range_float: this.form.floatingRange != 0 ? Number(new Decimal(this.form.floatingRange).div(new Decimal(100))) : 0,
                                order_interval: this.form.intervalTime || 0,
                            }
                        }

                        batchTradeDex(data).then(res => {
                            if (res.code == 2107 || (res.code == 2109 && res.type == 'now')) {
                                // 未授权 提示授权中。。
                                this.$message({
                                    message: this.$t('tip.processing'),
                                    type: 'success',
                                    center: true,
                                    customClass: 'shotMsg',
                                });
                                // 授权代币请求
                                dexWalletsApprove({
                                    web: this.web,
                                    account: this.account,
                                    approve_token: res.data.replace(',', '_'),//代币
                                    is_master_approve: 0,
                                    group_id: this.form.group_id,
                                }).then(re => {
                                    if (re.code == 2172 && re.type == 'confirm') {
                                        // 分拨授权
                                        this.$emit('openSubTransfer', {
                                            address_list: re.data.address_list,
                                            id_list: re.data.id_list,
                                            chain: re.data.chain,
                                            token: re.data.gas_token,
                                            approve_token: re.data.approve_token,
                                            transferWeb: this.web,
                                            transferExchange: this.exchange
                                        })
                                    } else if (re.code == 2172 && re.type == 'ignore') {
                                        // 忽略，重新操作
                                        this.handleOrder2('again')
                                    } else {
                                        // 授权成功
                                        this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
                                            confirmButtonText: this.$t('common.confirm'),//'继续操作',
                                            // showConfirmButton:false,
                                            center: true,
                                            showClose: false,
                                            customClass: 'deleteOk',
                                            // cancelButtonText: this.$t('common.back'),
                                            showCancelButton: false,
                                        })
                                    }
                                })
                            }
                            else if (res.code == 2109 && res.type == 'ignore') {
                                // 忽略，重新操作
                                this.handleOrder2('again')
                            } else if (res.code == 2109 && res.type == 'confirm') {
                                // 分拨授权
                                this.$emit('openSubTransfer', {
                                    address_list: res.data.address_list,
                                    id_list: res.data.id_list,
                                    chain: res.data.chain,
                                    token: res.data.gas_token,
                                    approve_token: res.data.approve_token,
                                    transferWeb: this.web,
                                    transferExchange: this.exchange
                                })
                            } else {
                                // console.log(res,'batchTradeDex')
                                this.$message({
                                    message: this.$t('tip.waitConfirmation'),
                                    type: 'success',
                                    center: true,
                                    customClass: 'shotMsg',
                                });

                                // 获取新数据 重置轮询 资金、订单、盘口相关
                                this.$emit('updateData')
                            }
                        })
                    })
                }
            },

            // 资金格式化
            balanceFormat(objectName, position) {
                if (this[objectName] && this[objectName][position]) {
                    return Math.floor(this[objectName][position] * 1000000) / 1000000
                } else {
                    return 0
                }
            },
            // 正数
            inputHandler(event, objectName, position) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this[objectName], position, value >= 0 ? value : '');
                });
            },
            // 正整数
            inputHandler2(event, objectName, position, min) {
                let minNum = min || 0
                let value = event.target.value.replace(/^(0+)|[^\d]+/g, '');
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this[objectName], position, value >= minNum ? Number(value) : '');
                });
            },
            // cex手动下单-价格 input变化
            priceChange(event) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                let result = value >= 0 ? value : ''
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.form, 'price', result);
                    if (result && this.form.number) {
                        // 价格 数量 得 金额
                        let total = (new Decimal(result).mul(new Decimal(this.form.number))).toString()
                        this.$set(this.form, 'total', total);
                    } else if (result && this.form.total) {
                        // 价格 金额 得 数量
                        let number = (new Decimal(this.form.total).div(new Decimal(result))).toString()
                        this.$set(this.form, 'number', number);
                    }
                });
            },
            // cex手动下单-数量 input变化
            numberChange(event) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                let result = value >= 0 ? value : ''
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.form, 'number', result);
                    if (result && this.form.price) {
                        // 价格 数量 得 金额
                        let total = (new Decimal(result).mul(new Decimal(this.form.price))).toString()
                        this.$set(this.form, 'total', total);
                    }
                });
            },
            // cex手动下单-金额 input变化
            totalChange(event) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                let result = value >= 0 ? value : ''
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.form, 'total', result);
                });

                if (result && this.form.price) {
                    // 价格 数量 得 金额
                    let number = Number(new Decimal(result).div(new Decimal(this.form.price)))
                    // 如果币的数量大于1, 默认保留1位数量小数点, 如果算出来的数量小于1. 保留4位小数点
                    if (number > 1) {
                        number = number.toFixed(1)
                    } else {
                        number = number.toFixed(4)
                    }
                    this.$set(this.form, 'number', number);
                }
            },
            // dex批量下单-交易量 input变化
            volumeChangeDex(event) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                let result = value >= 0 ? value : ''
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.form, 'volume', result);
                });

                if (result && this.form.transactionType >= 0) {
                    let totalDex
                    if (this.form.transactionType == 1) {
                        totalDex = result
                    } else {
                        totalDex = (new Decimal(result).mul(new Decimal(this.asksList[0].price))).toString()
                    }
                    this.$set(this.form, 'totalDex', totalDex);
                }
            },
            // dex批量下单-金额 input变化
            totalChangeDex(event) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                let result = value >= 0 ? value : ''
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.form, 'totalDex', result);
                });

                if (result && this.form.transactionType >= 0) {
                    let volume
                    if (this.form.transactionType == 1) {
                        volume = result
                    } else {
                        volume = Number(new Decimal(result).div(new Decimal(this.asksList[0].price))).toFixed(6)
                    }
                    this.$set(this.form, 'volume', volume);
                }
            },
            // 交易类型变化
            transactionTypeChange() {
                if (this.form.volume && this.form.transactionType >= 0) {
                    let totalDex
                    if (this.form.transactionType == 1) {
                        totalDex = this.form.volume
                    } else {
                        totalDex = (new Decimal(this.form.volume).mul(new Decimal(this.asksList[0].price))).toString()
                    }
                    this.$set(this.form, 'totalDex', totalDex);
                } else if (this.form.totalDex && this.form.transactionType >= 0) {
                    let volume
                    if (this.form.transactionType == 1) {
                        volume = this.form.totalDex
                    } else {
                        volume = (new Decimal(this.form.totalDex).div(new Decimal(this.asksList[0].price))).toString()
                    }
                    this.$set(this.form, 'volume', volume);
                }
            },
            // dex批量交易-浮动范围 input变化 范围为0-80
            floatChange(event) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                value = value > 80 ? 80 : value;
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.form, 'floatingRange', value >= 0 ? value : '');
                });
            },


            // 移动端判定相关方法
            // 打开选择量化工具弹窗
            openToolSelect() {
                this.tool_box_show = true
            },
        },
        watch: {
            addTokenWeb(newVal) {
                // 切换交易所时如果token_currency是默认值（usdt或sol）则更新默认值
                if (!!newVal && (this.toolform.token_currency == 'USDT' || this.toolform.token_currency == 'SOL')) {
                    this.$set(this.toolform, 'token_currency', this.solWebList.indexOf(newVal) !== -1 ? 'SOL' : 'USDT',)
                }
            },
        }
    })
</script>

<style scoped="scoped" lang="stylus">
    @import './toolBox.styl';
</style>